import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { useState } from "react";
import { useFilters } from "src/contexts/filters-context";
import { getChatType } from "src/utils/get-chat-type";

export const TypesOrdering = () => {
  const conversationType = getChatType();

  const options = [
    { value: "all", label: "Todas" },
    { value: "unread", label: "Não lidas" },
    { value: "unanswered", label: "Não Respondidas" },
    { value: "archived", label: "Arquivadas" },
  ];

  const optionsWithAnswered = [
    ...options,
    { 
      value: conversationType === "claim" ? "closed" : "answered", 
      label: conversationType === "claim" ? "Encerradas" : "Concluídas" 
    },
  ]

  const returnsOptions = [
    { value: "ALL", label: "Status Weello (Todas)" },
    { value: "statusWeello: pending", label: "Pendente" },
    { value: "statusWeello: not_recived", label: "Não recebido" },
    { value: "statusWeello: parcial_recived", label: "Recebimento Parcial" },
    { value: "statusWeello: received_damaged", label: "Recebimento com Avaria" },
    { value: "statusWeello: received_divergence", label: "Recebimento com divergencia"},
    { value: "statusWeello: completed", label: "Concluída" },
    { value: "statusWeello: cancelled", label: "Cancelado" },
  ];

  const filterSelector =
    conversationType === "chat"
      ? options
      : conversationType === "return"
      ? returnsOptions
      : optionsWithAnswered;

  const { filters, setFilters } = useFilters();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (index, filter) => {
    setFilters((prevState) => ({ ...prevState, filter, typeIndex: index }));
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <List component="nav" aria-label="Device settings">
        <ListItem
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
          sx={{
            height: "30px",
            cursor: "pointer",
            marginLeft: "16px",
          }}
        >
          <ListItemText
            primary={
              <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
                {filterSelector[filters.typeIndex]?.label}
              </Typography>
            }
          />
        </ListItem>
      </List>
      <UnfoldMoreIcon
        sx={{
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          cursor: "pointer",
          left: "8px",
        }}
        onClick={handleClickListItem}
      />
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
        sx={{ width: "100%" }}
      >
        {filterSelector.map((option, index) => (
          <MenuItem
            key={option.value}
            selected={index === filters.typeIndex}
            onClick={() => handleMenuItemClick(index, option.value)}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
